import './NewsPart.scss'

import { Link } from 'gatsby'
import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import getReadingDuration from 'Shared/utils/getReadingDuration'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { t } from 'ttag'

import PuxVideo, { PuxVideoType } from '../../PuxVideo/PuxVideo'

export interface NewsPartType {
  contentType: 'EasySoftwareNews'
  contentItemId: string
  path: string
  displayText: string
  createdUtc: string
  newsPerex: PuxWysiwygHtmlBodyType
  newsContent: PuxWysiwygHtmlBodyType
  newsImage: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

const rootClass = `news-part`

const NewsPart: FunctionComponent<NewsPartType> = (props) => {
  const date = new Date(props.createdUtc)
  const minutes = getReadingDuration([
    props.newsPerex.html,
    props.newsContent.html,
  ])

  const hasImage =
    props.newsImage?.mediaSelectorImage?.resizePaths?.length &&
    props.newsImage?.mediaSelectorImage?.resizePaths[0]?.length > 0
  const hasImageClass = hasImage ? `` : ` no-image`

  return (
    <div className={`pux-repeater-item ${rootClass}-wrapper`}>
      <div className={`${rootClass}-item`}>
        <div className={`${rootClass}-image${hasImageClass}`}>
          <Link to={`${getLocalizedUrl(props.path)}`}>
            <PuxMediaSelector {...props.newsImage} />
          </Link>
        </div>

        <div className={`${rootClass}-parameters`}>
          {`${date.toLocaleDateString()} | ${minutes} ${Number.parseInt(minutes) > 1
            ? t`Redmine.News.MinutesRead`
            : t`Redmine.News.MinuteRead`
            }`}
        </div>

        <h2 className={`${rootClass}-heading`}>
          <Link to={`${getLocalizedUrl(props.path)}`}>{props.displayText}</Link>
        </h2>

        <div className={`${rootClass}-perex`}>
          <PuxWysiwyg content={props.newsPerex} inline />
        </div>
      </div>
    </div>
  )
}

export default NewsPart
